@tailwind base;
@tailwind components;
@tailwind utilities;

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

h2 {
  @apply lg:text-2xl lg:mb-4 lg:font-normal text-lg mb-2 font-bold;
}

p {
  @apply pb-4;
}

a {
  @apply text-sky-900 hover:underline;
}

ul {
  @apply list-disc list-outside ml-8;
}

ul > li::before {
  content: "";
  @apply -mr-5;
}

ul > li {
  @apply indent-5;
}

[role="alert"].success {
  @apply border-green-300 bg-green-100 text-green-900;
}

[role="alert"].error {
  @apply border-red-300 bg-red-100 text-red-900;
}

[role="alert"] ul {
  @apply ml-4;
}
